import React from 'react';
import './App.css';
import Navbar from './components/common/Navbar';
import Footer from './components/common/Footer';
import Home from './components/pages/Home';
import ContactUs from './components/pages/ContactUs';
import { BrowserRouter as Router ,Route,Routes,Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
        <div className='font-proxima-nova'>  
        <ToastContainer />   
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/contactUs" element={<ContactUs />} />
          </Routes>
          <Footer />
        </div>

    </Router>
  );
}

export default App;
