import React from 'react'
import Hero from '../common/home/Hero'
import Rental from '../common/home/Rental'
import HowItWorks from '../common/home/HowItWorks'
import AppInfo from '../common/home/AppInfo'
import ContactUs from '../common/home/ContactUs'
import CustomerReview from '../common/home/CustomerReview'

function Home() {
  return (
    <div className='m-0 p-0'>
      <Hero/>
      <Rental/>
      <HowItWorks />
      <CustomerReview/>
      <AppInfo />
      <ContactUs />
    </div>
  )
}

export default Home
