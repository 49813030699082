import React,{useState,useEffect} from 'react'
import invertedup from '../../../assets/home/invertedup.svg'
import inverteddown from '../../../assets/home/inverteddown.svg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Xingoda from '../../../assets/navbar/Xingoda.svg'
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import { ProductService } from '../../../utils/ProductService';
import profile from '../../../assets/home/profile.svg'

function CustomerReview() {
  const [scale, setScale] = useState(1);
  const [products, setProducts] = useState([]);
  const [comments, setComments] = useState("It's a very useful and must try app by entire community as it increases social connection and save environment at the same time. I personally rented laptop from one of the authorised vendor and in user rental i am renting my unused items and earning money out of it")
    const responsiveOptions = [
        {
            breakpoint: '1200px',
            numVisible: 3,
            numScroll: 1
        },
        {
          breakpoint: '1000px',
          numVisible: 2,
          numScroll: 1
      },
        {
            breakpoint: '800px',
            numVisible: 1,
            numScroll: 1
        },
        {
          breakpoint: '600px',
          numVisible: 1,
          numScroll: 1
      },
        {
            breakpoint: '400px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    useEffect(() => {
        ProductService.getProductsSmall().then((data) => setProducts(data.slice(0, 9)));
    }, []);

    const productTemplate = (product) => {
        return (
            <button className=" w-[20vw] sm:w-[60vw]  h-auto flex gap-[1.5rem] justify-start items-center pl-[1rem] ml-auto mr-auto  bg-white shadow-md rounded-lg text-center  md:gap-[0.5rem]  " onClick={()=>{
              setComments(product.comment)} }
              >
                <img src={profile} alt="" />
                <div className='flex flex-col items-start'>
                  <h2 className='font-semibold text-lg pb-[0.1rem]'>{product.name}</h2>
                  <h2 className='text-xs'>{product.place}</h2>
                </div>
            </button>
        );
    };

  return (
    <div className='w-[100vw] h-[60vh] bg-[#F5F8FA] flex flex-col justify-center  items-center'>
        <h2 className='font-bold text-4xl mb-[3rem] sm:text-3xl'>What Our Customer Says</h2>
        <div className='relative w-[60vw] h-[30vh] flex flex-col justify-center items-center sm:w-[90vw]'>
            <img src={invertedup} alt=""  className='absolute top-0 left-0'/>
            <p className='w-[40vw] text-center sm:w-[80vw] sm:pt-[3rem] sm:pb-[3rem]'>{comments}</p>
            <img src={inverteddown} alt=""  className='absolute bottom-0 right-0'/>
        </div>
        <div className='w-[75vw] mt-[3rem]'>

        <Carousel value={products} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions} itemTemplate={productTemplate} circular={true} />
               
        </div>
        
       
    </div>
  )
}

export default CustomerReview
