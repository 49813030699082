import React from 'react'
import SearchItem from '../../../assets/home/SearchItem.svg'
import BookRental from '../../../assets/home/BookRental.svg'
import ViewProduct from '../../../assets/home/ViewProduct.svg'


function HowItWorks() {
  return (
    <div className='w-[100vw] h-[80vh] md:h-[auto] md:pt-[3rem] md:pb-[3rem] flex flex-col justify-center items-center gap-[5rem] sm:h-auto sm:pt-[4rem] '>
      <h1  className='font-bold text-4xl pt-[2rem]'>How it works</h1>
      <div className='w-[100vw] flex justify-evenly items-center pl-[6rem] pr-[6rem] md:gap-[2rem] md:pl-[3rem] md:pr-[3rem] sm:flex-col sm:w-[100vw] sm:gap-[3rem]'>
        <div className='w-[16vw] flex flex-col justify-center items-center gap-[2rem] md:w-[30%] sm:w-[100vw] sm:pl-[3rem] sm:pr-[3rem] '>
            <img src={SearchItem} alt="search image"  />
            <div className='flex flex-col justify-center items-center'>
                <h2 className="font-bold text-2xl mb-[1rem]">Search Item</h2>
                <p className='text-lg text-[#47586E] text-center'>Search the required item in the application you need</p>
            </div>
        </div>
        <div className='w-[16vw] flex flex-col justify-center items-center gap-[2rem] md:w-[30%] sm:w-[100vw] sm:pl-[3rem] sm:pr-[3rem]'>
        <img src={ViewProduct} alt="search image" />
            <div className='flex flex-col justify-center items-center'>
                <h2 className="font-bold text-2xl mb-[1rem]">View Product</h2>
                <p className='text-lg text-[#47586E] text-center'>Check the product in detail and availability to rent item</p>
            </div>
        </div>
        <div className='w-[16vw] flex flex-col justify-center items-center gap-[2rem] md:w-[30%] sm:w-[100vw] sm:pl-[3rem] sm:pr-[3rem]'>
            <img src={BookRental} alt="search image" />
            <div className='flex flex-col justify-center items-center'>
                <h2 className="font-bold text-2xl mb-[1rem]">Book for rental</h2>
                <p className='text-lg text-[#47586E] text-center'>Confirm your product renting with in easy and simple steps</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
