import React from 'react'
import Form  from  "../Form"
import playstore from "../../../assets/home/playstor.svg"
import applestore from "../../../assets/home/applestore.svg"

function ContactUs() {
  return (
    <div className='w-[100vw] h-[60vh] flex justify-around items-center md:h-auto md:p-[2rem] md:gap-[1rem] md:flex-col-reverse   sm:h-auto sm:p-[2rem] sm:gap-[2rem] sm:flex-col-reverse'  >
        <div className='flex flex-col gap-[2rem] md:w-[92vw] md:items-center md:flex-row md:justify-around'>
           <h2 className='font-bold text-4xl md:hidden sm:hidden'>Contact Us</h2>
           <p className='text-lg leading-[2.5rem] md:pl-[4rem]'>Xingoda <br/> 19,3rd cross, Vijaylakshmi layout, Kalkere <br /> Bengaluru-560043, Karnataka, India <br />support@xingoda.com <br /> </p>
           <div className='flex justify-around gap-4 md:flex-col md:w-[40vw] md:items-center md:gap-[4rem]'>
                <a href="http://play.google.com/store/apps/details?id=com.xingoda.app"><img src={playstore} alt="" /></a>
                <a href="http://play.google.com/store/apps/details?id=com.xingoda.app"><img src={applestore} alt="" /></a>
           </div>
        </div>
        <div className='h-[1px] w-[80vw] mt-[1rem] mb-[1rem] shadow-md hidden md:block bg-slate-500'></div>
        <div id='contactUs'>
            <Form />
        </div>
        <h2 className='font-bold text-4xl hidden md:block sm:block'>Contact Us</h2>
      
    </div>
  )
}

export default ContactUs
