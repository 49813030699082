import React from 'react'
import p2p from '../../../assets/home/p2p.svg'
import rentalservice from '../../../assets/home/rentalservice.svg'

function Rental() {
  return (
    <div className="w-[100vw] h-[73vh] md:h-auto md:pb-[3rem] bg-cover bg-[url('assets/home/rental.svg')]  bg-center bg-no-repeat flex justify-center items-center gap-[2rem] sm:h-auto flex-col">
        <h1  className='font-bold text-4xl pt-[3rem]'>Services</h1>
        <div className='w-[80%] h-auto flex justify-evenly items-center  md:w-[90%] sm:flex-col sm:gap-[3rem]'>
            <div className='w-[30%] md:w-[40%] sm:w-[100vw] sm:pl-[3rem] sm:pr-[3rem] sm:flex sm:flex-col sm:justify-center sm:items-center'>
                <h2 className='font-semibold text-2xl '>Peer to Peer Rental</h2>
                <p className='bg-[#FCF5E3] rounded-full w-fit pl-[1rem] pr-[1rem] pt-[0.4rem] pb-[0.4rem] text-[#B37B00] font-semibold mt-[1.2rem] mb-[1.5rem] '>Rent from Neighbourhood</p>
                <img src={p2p} alt="p2p photo" />
                <p className='mt-[1rem] text-[#47586E] text-xl'> Unlock the value in seldom-used items at home! Our platform offers a seamless way to rent out your rarely used belongings, turning idle assets into opportunities for others.</p>
            </div>
            <div className='w-[30%] md:w-[40%] sm:w-[100vw] sm:pl-[3rem] sm:pr-[3rem] sm:flex sm:flex-col sm:justify-center sm:items-center'>
                <h2 className='font-semibold text-2xl'>Rental Service</h2>
                <p className='bg-[#FCF5E3] rounded-full w-fit pl-[1rem] pr-[1rem] pt-[0.4rem] pb-[0.4rem] text-[#B37B00] font-semibold mt-[1.2rem] mb-[1.5rem]'>Rent from Vendors</p>
                <img src={rentalservice} alt="p2p photo" />
                <p className='mt-[1rem] text-[#47586E] text-xl'>Join our common rental platform and amplify your business! We provide a centralized hub for renters to discover and connect with a variety of services</p>
            </div>
        </div>
      
    </div>
  )
}

export default Rental
