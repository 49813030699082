import React ,{useState} from "react";
import Xingoda from "../../assets/navbar/Xingoda.svg";
import { Link } from "react-router-dom";
import Button from "../common/Button";
import { IoMenu } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";

function Navbar() {
  const [menu,setMenu] =useState(false);

  const handleClickOpen=()=>{
    setMenu(!menu);
  }
  return (
    <header className="h-[4rem] bg-white text-black  flex justify-between items-center drop-shadow-[0px_4px_16px_0px_rgba(87, 89, 125, 0.1)] pl-[6rem] pr-[6rem] md:ml-[3rem] md:mr-[3rem] sm:m-0 sm:pl-[1rem] sm:pr-[1rem]">
      <img src={Xingoda} alt="Logo" className="w-[5rem]"  />
      <div className="flex gap-[2rem] font-semibold sm:hidden">
        <div className="flex flex-around gap-[2rem] items-center ">
          <a href="#">Home</a>
          <a href="#contactUs">Contact Us</a>
        </div>
        <div >
          <Button text="Get Started" />
        </div>
      </div>
      <button className="text-3xl hidden sm:block" onClick={handleClickOpen}>
        <IoMenu />
      </button>

      {/* MOBILE POPUP */}

      <div className={`${
        menu ? "hidden sm:flex sm:flex-col sm:gap-[2rem] sm:font-semibold sm:absolute sm:w-[98vw] sm:top-2 sm:z-10 sm:bg-white sm:m-0 sm:p-[2rem]" : "hidden"
      }`}>
          <div className="sm:flex sm:flex-col sm:flex-center sm:gap-[1rem] sm:items-center mt-[3rem] ">
          <img src={Xingoda} alt="Logo" className="w-[5rem] absolute top-2 left-3"  />
          <div className="w-[90%] h-[1px] bg-slate-400"></div>
          <a href="#contactUs">Contact Us</a>
        </div>
        <div >
          <Button href="#" text="Get Started" />
        </div>
        <button className="sm:text-3xl absolute top-3 right-3" onClick={handleClickOpen}>
          <RxCross2 />
        </button>
      </div>


    </header>
  );
}

export default Navbar;
