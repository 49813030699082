import React from 'react'
import { AiFillLinkedin ,AiFillInstagram ,AiOutlineTwitter,AiFillCopyrightCircle } from "react-icons/ai";
import { SiFacebook } from "react-icons/si";
import { BsDot } from "react-icons/bs";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import xingoda from '../../assets/navbar/Xingoda.svg'


function Footer() {
  return (
    <footer className='bg-[#F2F5F8] w-[100vw] h-[20vh] flex flex-col justify-center items-center'>
      <div className='text-[#01934F] text-2xl flex gap-[2rem] pb-[1.8rem]'>
          <a href="https://www.linkedin.com/company/xingodaxingoda/"><AiFillLinkedin /></a>
          <a href="https://www.instagram.com/xingoda?igsh=MTM3bXk3N2N3Nm10aQ=="><AiFillInstagram /></a>
          <a href="https://www.facebook.com/profile.php?id=61556341650154&mibextid=ZbWKwL"><SiFacebook /></a>
          <a href="https://twitter.com/xingoda?t=bRnq9l6heChLc--hh_q63A&s=09"><AiOutlineTwitter /></a>
          {/* <ReactWhatsapp number="+91-911-156-9950" message="Hello Xingoda!!!" >watsapp</ReactWhatsapp> */}
          <FloatingWhatsApp 
          phoneNumber="9945924000"
          accountName="Xingoda"
          statusMessage="We will get back to you soon"
          avatar={xingoda}
          allowEsc
          allowClickAway
          notification
          notificationSound 
          />
          
      </div>
      <div className='w-[90%] h-[1px] bg-[#546881]'></div>
      <div  className='flex justify-center items-center gap-[1rem] mt-[1rem] sm:gap-1'>
         <div>Xingoda</div>
         <BsDot />
         <div>All rights reserved</div>
         <BsDot />
         <div>Copyright</div>
         <AiFillCopyrightCircle />
         <div>2024-25</div>
      </div>
    </footer>
  )
}

export default Footer
