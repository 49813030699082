import React from 'react'
import vector from '../../../assets/home/Vector.svg'
import { FaStar } from "react-icons/fa6";

function AppInfo() {
  return (
    <div className='w-[100vw] h-[34vh] bg-[#01934F] relative  flex items-center sm:h-auto sm:pt-[1rem] sm:pb-[1rem]'>
        <img src={vector} className='absolute right-0 bottom-0 w-[40%]' alt="" />
        <div className='w-[70%] h-[80%] flex justify-evenly items-center sm:w-[100vw]'>
            <div className='text-white font-extrabold text-5xl sm:text-3xl'>200+
                <div className='text-lg sm:text-base font-medium mt-5 sm:mt-2 '>Users</div>
            </div>
            <div className='text-white font-extrabold text-5xl sm:text-3xl'>10+
                <div className='text-lg sm:text-base font-medium mt-5 sm:mt-2'>Reviews</div>
            </div>
            <div className='text-white font-extrabold text-5xl'>
               <div className='flex gap-3 sm:text-3xl'> 5<FaStar className='text-[#FFCC00] text-5xl sm:text-3xl ' /> </div>
                <div className='text-lg sm:text-base font-medium mt-5 sm:mt-2'> Rating </div>
            </div>
        </div>
      
    </div>
  )
}

export default AppInfo
