import React from 'react'

function Button({text}) {
  return (
    <div className='flex items-center justify-center'>
        <button className='w-fit bg-[#01934F] text-white pl-[2rem] pr-[2rem] pt-[0.7rem] pb-[0.7rem] rounded-full sm:w-[80vw]' >{text}</button>
    </div >
  )
}

export default Button
