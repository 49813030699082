import React ,{useState} from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Form() {

  const [name,setName] = useState("");
  const [email,setEmail] = useState("");
  const [number,setNumber] = useState("");
  const [message,setMessage] = useState("");


  const handleSubmit = (event) => {
    event.preventDefault();

    const templateParam = {
      name:name,
      email:email,
      number:number,
      message:message
    }
    
    // Use your EmailJS service ID, template ID, and user ID
    const serviceId = 'service_sniiwa6';
    const templateId = 'template_13yqden';
    const userId = 'MlJhJ4YoQ7cGwrPQx';

    emailjs.send(serviceId, templateId, templateParam, userId)
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setName('');
        setEmail('');
        setNumber('');
        setMessage('');
        toast.success("Form sent successfully")
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
        toast.error("Form sending failed")
      });
  };



  return (
    <form onSubmit={handleSubmit} className='w-[30vw] flex flex-col gap-[1.5rem]  md:w-[90vw] md:items-center  sm:w-[90vw] sm:items-center'>
        <input type="text" placeholder='Your Name' className='border p-[1rem] rounded-md w-[90%]' value={name} onChange={(e)=>{setName(e.target.value)}}/>
        <input type="email" placeholder='Your email' className='border p-[1rem] rounded-md w-[90%]' value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
        <input type="number " placeholder='Phone Number' className='border p-[1rem] rounded-md w-[90%]' value={number} onChange={(e)=>{setNumber(e.target.value)}}/>
        <input type="text" placeholder='Message' className='border p-[1rem] rounded-md w-[90%] h-[10vh]' value={message} onChange={(e)=>{setMessage(e.target.value)}} />
        <button type="submit" value="Submit" className="w-fit bg-[#01934F] text-white pl-[2rem] pr-[2rem] pt-[0.7rem] pb-[0.7rem] rounded-full sm:w-[80vw] border-none" >Submit</button>
  
       
    </form>
  )
}

export default Form
