export const ProductService = {
    getProductsData() {
        return [
            {
                id: '1000',
                name: 'Jaya T R',
                place: 'Banglore ,Karnatka',
                image: '1000 bamboo-watch.jpg',
                comment:"Very useful renting solution. Thank you xingoda for making our lives easier and better environment",
                rating: 5
            },
            {
                id: '1001',
                name: 'Anil Jadhav',
                place: 'Banglore ,Karnatka',
                image: 'bamboo-watch.jpg',
                comment:"Good app with a great purpose of sustainable resources usage",
                rating: 5
            },
            {
                id: '1002',
                name: 'Kushala Ralpati Srinivas',
                place: 'Banglore ,Karnatka',
                image: 'bamboo-watch.jpg',
                comment:"It's a very useful and must try app by entire community as it increases social connection and save environment at the same time. I personally rented laptop from one of the authorised vendor and in user rental i am renting my unused items and earning money out of it.",
                rating: 5
            },
            
            
        ];
    },

    getProductsMini() {
        return Promise.resolve(this.getProductsData().slice(0, 5));
    },

    getProductsSmall() {
        return Promise.resolve(this.getProductsData().slice(0, 10));
    },

    getProducts() {
        return Promise.resolve(this.getProductsData());
    },

    getProductsWithOrdersSmall() {
        return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
    },

    getProductsWithOrders() {
        return Promise.resolve(this.getProductsWithOrdersData());
    }
};

