import React from 'react'


function Hero() {
  return (
    <div className="w-[100vw] h-[75vh] bg-cover bg-[url('assets/home/herobg.svg')]  bg-center bg-no-repeat md:h-[50vh] sm:h-[50vh]" >
      <div className='flex flex-col pt-[5rem] pl-[6rem] md:pl-[3rem] md:pt-[4rem] sm:pl-[1rem] sm:pr-[1rem] sm:pt-[2rem]'>
        <div className='text-[#1D242D] text-5xl font-bold leading-[4rem] md:text-4xl md:leading-[2.5rem] sm:text-2xl'>
        One stop solution for all <br /> your rental
        </div>
        <p className='text-[#3D4C5E] w-[40vw] text-lg font-normal leading-[2rem] mt-[2rem] md:w-[45vw] md:text-base sm:w-[60vw] sm:mt-[1rem]
        sm:text-sm'>
        Rentals help to save the environment and reduce carbon footprint and provide better usability of the products.
        </p>
        <p className='text-[#3D4C5E] w-[40vw] text-lg font-normal leading-[2rem] mt-[0rem] md:w-[45vw] md:text-base sm:w-[55vw] sm:mt-[1rem]
        sm:text-sm'>
            Discover a unified rental experience, where user rentals, vendor offerings, and services converge on a single, comprehensive platform.
          </p>
      </div>
    </div>
  );
}

export default Hero
